import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import TermsModule from '@/store/model/TermsModule'
import Company from './Company'
import User from './User'
import Api from './Api'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import SelectOption from './interface/SelectOption'

export default class Terms {
    public id: string | null = null;

    public name: string = '';

    public type: string = 'advertiser';

    public terms: string = '';

    public created_at: string | null = null;

    public updated_at: string | null = null;

    public company_id: string | null = null;

    public user_id: string | null = null;

    private _company: Company | null = null;

    public get company(): Company | null {
      if (this.company_id && (!this._company || this.company_id != this._company.id)) {
        this._company = Company.find(this.company_id)
      }
      return this._company
    }

    private _user: User | null = null;

    public get owner(): User | null {
      if (this.user_id && (!this._user || this.user_id != this._user.id)) {
        this._user = User.find(this.user_id)
      }
      return this._user
    }

    public save() {
      const api = new Api()

      if (this.id) {
        return api.put(`terms/${this.id}`, {
          name: this.name,
          type: this.type,
          terms: this.terms,
        }).then(this.onSave).catch(this.onError)
      }
      return api.post('terms', {
        name: this.name,
        type: this.type,
        terms: this.terms,
      }).then(this.onSave).catch(this.onError)
    }

    public delete() {
      const api = new Api()

      return api.delete(`terms/${this.id}`, {}).then(this.onDelete).catch(this.onError)
    }

    private onSave(response: any) {
      const terms = Terms.toObject(response.data.result.terms)

      WebMessage.success(`Terms "${terms.name}" saved!`)

      return response
    }

    private onDelete(response: any) {
      const terms = Terms.filter(response.data.result.deleted)

      let message

      if (terms.length == 1) {
        message = `Terms "${terms[0].name}" deleted!`
      } else {
        message = 'Terms deleted!'
      }

      WebMessage.success(message)

      Terms.module.delete(terms)

      return response
    }

    private onError(error: any) {
      return error
    }

    public static toObject(data: any, cache: boolean = true) {
      const term = new Terms()

      term.id = data.id
      term.name = data.name
      term.type = data.type
      term.terms = data.terms
      term.created_at = data.created_at
      term.updated_at = data.updated_at
      term.company_id = data.company_id
      term.user_id = data.user_id

      if (data.company) {
        Company.toObject(data.company)
      }

      if (data.user) {
        User.toObject(data.user)
      }

      //  Cache Object
      if (cache) Terms.module.update(term)

      return term
    }

    public static toObjectList(data: any, cache: boolean = true) {
      const terms = new Array<Terms>()
      data.forEach((value: any) => {
        const term = Terms.toObject(value, false)
        terms.push(term)
      })

      //  Cache Object
      if (cache) Terms.module.update(terms)

      return terms
    }

    public toOption(): SelectOption {
      return new SelectOption(this.name, this.id, null, this.type)
    }

    /// State Management
    public static get module(): TermsModule {
      if (!store.hasModule('terms')) {
        store.registerModule('terms', TermsModule)
      }

      return getModule(TermsModule)
    }

    public static find(id: string): Terms | null {
      const o = Terms.module.data.find(terms => terms.id === id)
      return o instanceof Terms ? o : null
    }

    public static filter(ids: string[]): Terms[] {
      return Terms.module.data.filter(terms => terms.id && ids.includes(terms.id))
    }

    public static async get(id: string): Promise<Terms | null> {
      return Terms.module.find(id)
    }

    public static async paginate(options: PaginateOptions) {
      return Terms.module.paginate(options)
    }
}
