import moment from 'moment'
import { clone as _clone } from 'lodash'
import BigNumber from 'bignumber.js'
import Model from './interface/Model'
import MediaPackage from './MediaPackage'
import Api from './Api'
import SelectOption from './interface/SelectOption'
import DynamicRate from './DynamicRate'

export default class MediaPlanItem extends Model {
  public number: number = 1

  public name: string = ''

  public creative_length: number = 30

  public media_plan_id: string | null = null

  public media_package_id: string | null = null

  public media_package: MediaPackage | null = null

  public start_at: string = moment().format('YYYY-MM-DD')

  public end_at: string = moment()
    .endOf('month')
    .format('YYYY-MM-DD')

  public impressions: number = 0

  public model: string = 'cpm'

  public gross_rate: number = 0

  public net_rate: number = 0

  public gross_cost: number = 0

  public net_cost: number = 0

  public agency_commission: number = 0

  public type: string = 'default'

  public notes: string = ''

  public max_avails: number = 0

  public grps: number = 0

  public dynamic_rate: null | DynamicRate = null

  public dynamic_rate_id: null | string = null

  public metadata: any = {
    program_name: '',
    order_type: 'cash',
    spots: [] as number[],
    flight_time: {
      start_at: '10:00:00',
      end_at: '23:59:59',
    },
    demo_target: 0,
    days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    targetting: {
      include: {
        dmas: [],
        states: [],
        zipcodes: [],
      },
      exclude: {
        dmas: [],
        states: [],
        zipcodes: [],
      },
    },
  }

  public metrics: any = {}

  public has_listener: boolean = false
  /**
   * UI Form Fields
   */

  public visible: boolean = true

  public edit: boolean = true

  public _showDetails: boolean = true

  public _loading: boolean = false

  public get period() {
    let start = moment()
      .month(4)
      .startOf('month')
    let end = moment()
      .month(9)
      .endOf('month')

    if (moment(this.start_at).isBetween(start, end)) {
      return 'april_september'
    }
    return 'october_march'
  }

  public get topISCI(): string {
    if (!this.metrics.isci_codes) return ''
    return this.metrics.isci_codes.sort((a: any, b: any) => b.impressions - a.impressions)[0]
      .isci_code
  }

  public get formAgencyCommission() {
    return this.agency_commission
  }

  public set formAgencyCommission(agency_commission: number) {
    this.agency_commission = agency_commission
    // Recalculate values
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      }
    } else if (this.impressions > 0 && this.net_rate > 0) {
      this.net_cost = +this.bnNetRate.times(this.bnImpressions.div(1000))
      this.gross_cost = +this.bnNetCost.div(
        this.bnAgencyCommission
          .div(100)
          .negated()
          .plus(1),
      )
      this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
    }
  }

  public get formSpotImpressions() {
    return this.impressions / (this.total_spots > 0 ? this.total_spots : 1)
  }

  public set formSpotImpressions(impressions: number) {
    this.formImpressions = impressions * (this.total_spots > 0 ? this.total_spots : 1)
  }

  public get formImpressions() {
    return this.impressions
  }

  public set formImpressions(impressions: number) {
    this.impressions = impressions

    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_rate > 0) {
        this.gross_cost = +this.bnGrossRate.times(this.bnImpressions.div(1000))
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions > 0 && this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions == 0 && this.gross_cost == 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.net_rate = 0
      }
    } else if (this.impressions > 0 && this.net_rate > 0) {
      this.net_cost = +this.bnNetRate.times(this.bnImpressions.div(1000))
      this.gross_cost = +this.bnNetCost.times(this.bnAgencyCommission.div(100).plus(1))
      this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
    } else if (this.impressions > 0 && this.net_cost > 0) {
      this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      this.gross_cost = +this.bnNetCost.times(this.bnAgencyCommission.div(100).plus(1))
      this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
    } else if (this.impressions == 0 && this.net_cost == 0 && this.net_rate == 0) {
      this.gross_cost = 0
      this.gross_rate = 0
    }
  }

  public get formGrossCost() {
    return this.gross_cost
  }

  public set formGrossCost(gross_cost: number) {
    // if (this.gross_cost == gross_cost) return

    this.gross_cost = gross_cost
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions == 0 && this.gross_cost == 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.net_rate = 0
      } else if (this.impressions == 0 && this.gross_rate > 0 && this.gross_cost > 0) {
        this.impressions = +this.bnGrossCost
          .div(this.gross_rate)
          .times(1000)
          .integerValue()
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions > 0 && this.gross_cost == 0) {
        this.gross_rate = 0
        this.net_cost = 0
        this.net_rate = 0
      }
    }
  }

  public get formNetCost() {
    return this.net_cost
  }

  public set formNetCost(net_cost: number) {
    // if (this.net_cost == net_cost) return
    this.net_cost = net_cost

    if (!this.isLinear) {
      if (this.impressions > 0 && this.net_cost > 0) {
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
        this.gross_cost = +this.bnNetCost.div(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions == 0 && this.net_cost == 0 && this.net_rate == 0) {
        this.gross_cost = 0
        this.gross_rate = 0
      } else if (this.impressions == 0 && this.net_cost > 0 && this.net_rate > 0) {
        this.impressions = +this.bnNetCost
          .div(this.net_rate)
          .times(1000)
          .integerValue()
        this.gross_cost = +this.bnNetCost.div(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions > 0 && this.net_cost == 0) {
        this.gross_rate = 0
        this.gross_cost = 0
        this.net_rate = 0
      }
    }
  }

  public get formNetRate() {
    return this.net_rate
  }

  public set formNetRate(net_rate: number) {
    // if (this.net_rate == net_rate) return
    this.net_rate = net_rate
    if (!this.isLinear) {
      if (this.impressions > 0 && this.net_rate > 0) {
        this.net_cost = +this.bnNetRate.times(this.bnImpressions.div(1000))
        this.gross_cost = +this.bnNetCost.div(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions == 0 && this.net_cost == 0 && this.net_rate == 0) {
        this.gross_cost = 0
        this.gross_rate = 0
      } else if (this.impressions == 0 && this.net_cost > 0 && this.net_rate > 0) {
        this.impressions = +this.bnNetCost
          .div(this.net_rate)
          .times(1000)
          .integerValue()
        this.gross_cost = +this.bnNetCost.div(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.gross_rate = +this.bnGrossCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions > 0 && this.net_rate == 0) {
        this.net_cost = 0
        this.gross_cost = 0
        this.gross_rate = 0
      }
    }
  }

  public set formGrossRate(gross_rate: number) {
    // if (this.gross_rate == gross_rate) return

    this.gross_rate = gross_rate
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_rate > 0) {
        this.gross_cost = +this.bnGrossRate.times(this.bnImpressions.div(1000))
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions == 0 && this.gross_cost == 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.net_rate = 0
      } else if (this.impressions == 0 && this.gross_rate > 0 && this.gross_cost > 0) {
        this.impressions = +this.bnGrossCost
          .div(this.gross_rate)
          .times(1000)
          .integerValue()
        this.net_cost = +this.bnGrossCost.times(
          this.bnAgencyCommission
            .div(100)
            .negated()
            .plus(1),
        )
        this.net_rate = +this.bnNetCost.div(this.bnImpressions.div(1000))
      } else if (this.impressions > 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.gross_cost = 0
        this.net_rate = 0
      }
    }
  }

  public get bnAgencyCommission() {
    return new BigNumber(this.agency_commission)
  }

  public get bnImpressions() {
    return new BigNumber(this.impressions)
  }

  public get bnNetRate() {
    return new BigNumber(this.net_rate)
  }

  public get bnNetCost() {
    return new BigNumber(this.net_cost)
  }

  public get bnGrossRate() {
    return new BigNumber(this.gross_rate)
  }

  public get bnGrossCost() {
    return new BigNumber(this.gross_cost)
  }

  public get formGrossRate() {
    return this.gross_rate
  }

  public get formStartAt() {
    return this.start_at
  }

  public set formStartAt(start_at: string) {
    this.start_at = start_at
    this.updateSpots()
  }

  public get formEndAt() {
    return this.end_at
  }

  public set formEndAt(end_at: string) {
    this.end_at = end_at
    this.updateSpots()
  }

  public get total_spots() {
    return this.metadata.spots.reduce(
      (total: number, spot: number | string) =>
        total + (typeof spot === 'string' ? Number(spot) : spot),
      0,
    )
  }

  private updateSpots() {
    if (this.start_at && this.end_at) {
      const start = moment(this.start_at)
      const end = moment(this.end_at)
      const spots = []
      const local_spots = _clone(this.metadata.spots)
      let count = 0
      if (start.isSame(end)) {
        const value = local_spots[count++] ?? 0
        spots.push(value)
      } else {
        while (start.isBefore(end)) {
          const value = local_spots[count++] ?? 0
          spots.push(value)
          start.add(7, 'days')
        }
      }
      this.metadata.spots = spots
    }
  }

  public get short_name() {
    const limit = 50
    if (!this.name) return 'Untitled Line Item'
    return this.name.length > limit ? `${this.name.substring(0, limit)}...` : this.name
  }

  public get isLinear() {
    return ['media_ocean', 'strata'].includes(this.type)
  }

  public setDynamicRate(dynamic_rate_id: null | string) {
    if (!dynamic_rate_id) {
      this.dynamic_rate_id = null
      this.dynamic_rate = null
      return
    }

    this._loading = true
    DynamicRate.find(dynamic_rate_id).then((dynamic_rate: DynamicRate) => {
      this.dynamic_rate = dynamic_rate
      this.dynamic_rate_id = dynamic_rate.id
      if (!dynamic_rate.allow_overwrite || !this.formGrossRate) {
        this.formGrossRate = dynamic_rate.rate
      }
      this._loading = false
    })
  }

  constructor(source: any = {}) {
    super()

    Object.assign(this, source)

    return this
  }

  public async searchOptions(query: any) {
    const api = new Api(false)
    return api
      .get('media_plans/item/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)

    if (source.start_at) {
      instance.start_at = source.start_at.includes('T')
        ? source.start_at.split('T')[0]
        : moment(source.start_at).format('YYYY-MM-DD')
    }

    if (source.end_at) {
      instance.end_at = source.end_at.includes('T')
        ? source.end_at.split('T')[0]
        : moment(source.end_at).format('YYYY-MM-DD')
    }

    const base = new MediaPlanItem()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
    } else {
      instance.metadata = { ...base.metadata }
    }

    return instance
  }
}
