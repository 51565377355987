import { clone as _clone } from 'lodash'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Api from '@/models/Api'
import PaginateOptions from './PaginateOptions'
import WebMessage from '../WebMessage'
import { capitalize, randomUUID } from './Common'
import SelectOption from './SelectOption'

export default class Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: null as string | null,
      plural: null as string | null,
    },
  }

  public id: string | null = null

  private _formId: string = ''

  public get formId(): string {
    return this.id ?? this._formId
  }

  protected get apiData() {
    return this
  }

  public get instance_id(): string {
    const system = getModule(SystemtModule)
    return system._uuid
  }

  constructor() {
    if (!this.formId) this._formId = randomUUID()
  }

  public clone() {
    const clone = _clone(this)
    this._formId = randomUUID()
    return clone
  }

  public save() {
    if (!this.api_settings.paths.singular) {
      return Promise.reject(new Error('Invalid API settings'))
    }
    const api = new Api()
    if (this.api_settings.save_mode === 'form') {
      return api
        .post(
          this.id
            ? `${this.api_settings.paths.singular}/${this.id}`
            : this.api_settings.paths.singular,
          this.apiData,
        )
        .then(response =>
          // @ts-ignore
          this.onSave(response, { target: this.api_settings.paths.singular, name: this.name }))
        .catch(this.onError)
    }
    return api
      .post(
        this.id
          ? `${this.api_settings.paths.singular}/${this.id}`
          : this.api_settings.paths.singular,
        this.apiData,
      )
      .then(response =>
        // @ts-ignore
        this.onSave(response, { target: this.api_settings.paths.singular, name: this.name }))
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`${this.api_settings.paths.singular}/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  public async find(id: string, options: any = {}): Promise<any | null> {
    if (typeof this.api_settings.paths.singular !== 'string') {
      return Promise.reject(new Error('Invalid API settings'))
    }
    const api = new Api(false)
    return (
      api
        .get(`${this.api_settings.paths.singular}/${id}`, options)
        // @ts-ignore
        .then(response => this.toObject(response.data.result[this.api_settings.paths.singular]))
        .catch(() => null)
    )
  }

  public static async find<T extends Model>(this: new () => T, id: string, options: any = {}) {
    return new this().find(id, options)
  }

  public async paginate(options: PaginateOptions) {
    if (!this.api_settings.paths.plural) {
      return Promise.reject(new Error('Invalid API settings'))
    }
    const api = new Api(false)
    return api
      .get(`${this.api_settings.paths.plural}/paginate`, options)
      .then(response => {
        // Parse & cache data
        // @ts-ignore
        const data = this.toObjectList(response.data.result[this.api_settings.paths.plural])

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  public static async paginate<T extends Model>(this: new () => T, options: PaginateOptions) {
    return new this().paginate(options)
  }

  public async searchOptions(query: any) {
    const api = new Api(false)
    return api
      .get(`${this.api_settings.paths.plural}/search/option`, query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }

  public static async searchOptions<T extends Model>(this: new () => T, query: any) {
    return new this().searchOptions(query)
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)

    return instance
  }

  public static toObject<T extends Model>(this: new () => T, source: any): T {
    return new this().toObject(source)
  }

  public toObjectList(source: any) {
    return source.map((item: any) => this.toObject(item))
  }

  public static toObjectList<T extends Model>(this: new () => T, source: any): T[] {
    return new this().toObjectList(source)
  }

  protected onSave(response: any, meta: any = null) {
    if (meta) {
      WebMessage.success(`${capitalize(meta.target.replaceAll('_', ' '))} "${meta.name}" saved!`)
    }

    return response
  }

  private onDelete(response: any, meta: any = null) {
    if (meta) {
      WebMessage.success(`${capitalize(meta.target.replaceAll('_', ' '))} "${meta.name}" deleted!`)
    }

    return response
  }

  private onError(error: any) {
    return error
  }
}
